.bg {
    background-size: contain ;
    background-repeat: no-repeat;
    background-position: top;
    width: 100%;
    min-height: 40vh;
    background-color: #ffffff;
}



ccard:hover {
    cursor: pointer;
}