.processable {
    color: green;
    font-size: 18px;
    cursor: pointer;
  }
  
  .not-processable {
    color: red;
    font-size: 18px;
    cursor: pointer;
  }
  
  span[title]:hover::after {
    content: attr(title);
    position: absolute;
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 1000;
    margin-left: 10px;
    opacity: 0.75;
  }